<div
  *ngIf="currentTheme$ | async as currentTheme"
  [ngClass]="{ 'w-52': isMobile || isExpanded, 'w-18': !isMobile && !isExpanded }"
  class="flex h-full flex-col items-center overflow-hidden bg-gray-75 text-gray-700 dark:bg-gray-800 dark:text-gray-300">
  <a href="#" [class.mx-2]="!isExpanded" [class.mx-5]="isExpanded" class="my-3">
    <pd-paidiem-logo [letterLogo]="!isMobile && !isExpanded"></pd-paidiem-logo>
  </a>
  <div class="w-full flex-1 px-2">
    <div class="flex w-full flex-col items-center text-center">
      <a
        *ngFor="let navItem of topNavItems"
        [routerLink]="[navItem.path]"
        [routerLinkActive]="['text-primary', 'dark:text-primary-light']"
        matTooltip="{{ !isExpanded ? navItem.label : null }}"
        [attr.data-cy]="navItem.cypressId"
        (click)="navigationClicked.emit()"
        [matTooltipShowDelay]="300"
        [matTooltipPosition]="'after'"
        [class.justify-center]="!isExpanded && !isMobile"
        class="left-nav-menu-item mt-2 flex h-10 w-full items-center rounded px-3 text-left hover:bg-gray-200 dark:hover:bg-gray-900"
        href="#">
        <i style="min-width: 18px" class="fa {{ navItem.icon }}"></i>
        <span *ngIf="isMobile || isExpanded" class="ml-2 text-[0.8rem] font-semibold">{{ navItem.label }}</span>
      </a>
    </div>
  </div>
  <div *ngIf="!isMobile" class="mb-1 mt-auto flex w-full justify-end">
    <div (click)="toggleExpand()">
      <i [ngClass]="isExpanded ? 'fa-angles-left' : 'fa-angles-right'" class="fa-solid cursor-pointer px-6 text-xs text-gray-400"></i>
    </div>
  </div>
</div>
