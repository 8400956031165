import { Injectable } from '@angular/core';
import { Pager } from '@entities/pagers/pager.class';
import { QueryFilters } from '@entities/shared/query-filters.class';
import { ApiService } from 'src/app/services/api/api.service';
import { PushNotificationCollection } from '@entities/push-notification/push-notification-collection.entity';
import { Observable, shareReplay } from 'rxjs';
import { entity } from '@utils/rxjs';
import { User } from '@entities/users/user.entity';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  public constructor(private api: ApiService) {}

  public retrieveNotificationsByUserId(
    pager: Pager<PushNotificationCollection>,
    filters?: QueryFilters
  ): Observable<Pager<PushNotificationCollection>> {
    return this.api
      .getPagedEntities<PushNotificationCollection>({
        entityClass: PushNotificationCollection,
        path: 'push-notifications',
        pager: pager,
        filters: filters,
      })
      .pipe(shareReplay());
  }

  public updateLastCheckedPushNotificationForUser(userId: number) {
    return this.api
      .patch('users/' + userId + '/last-notifications-checked', {})
      .pipe(entity<User>(User), shareReplay());
  }
}
