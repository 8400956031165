import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';
import { first, Subscription } from 'rxjs';
import { AuthenticatedUserService } from '@services/authenticated-user/authenticated-user.service';
import { AppStateService } from '@services/app-state/app-state.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PageStateService } from '@services/page-state/page-state.service';
import { SidenavService } from '@services/sidenav/sidenav.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { UserService } from '@services/user/user.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@environment';
import { SegmentStrategy } from '@entities/analytics/strategies/segment-tracking.strategy';
import cloneDeep from 'lodash-es/cloneDeep';
import * as Sentry from '@sentry/browser';
import { UserRole } from '@entities/users/user-role.enum';
import { PageTitleStrategy } from '@core/strategies/page-title.strategy';
import { AppComponent } from './app.component';
import { BankingDetailsService } from '@services/banking-details/banking-details.service';
import { CountryCode } from '@services/geo/geo.service';

@Component({
  selector: 'pd-app-authenticated-embedded-layout',
  providers: [PageStateService, PageTitleStrategy],
  templateUrl: './app-authenticated-embedded-layout.component.html',
  styles: ``,
})
export class AppAuthenticatedEmbeddedLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('rightPanel', { static: false }) private rightSidePanel: MatSidenav;
  @ViewChild('rightPanelContent', { static: false, read: ViewContainerRef }) private viewContainerRef: ViewContainerRef;
  public isExpanded: boolean = false;
  public isMobile: boolean = false;
  public isLoadingAuthenticatedUser: boolean = true;
  public hasAcceptedTerms: boolean = false;
  public hasBankingDetails: boolean;
  public isAuthenticated = false;
  public hasAgreed = false;
  public showPrivacyPolicy = false;
  public showTermsAndConditions = false;
  public user: AuthenticatedUser = null;
  private startedLoading: boolean = false;
  private navigationSubscription: Subscription = new Subscription();
  private currentRoute: string;
  private subscriptions = new Subscription();
  private segmentStrategy = new SegmentStrategy(environment.analytics.segmentWriteKey);
  public constructor(
    private appStateService: AppStateService,
    private breakpointObserver: BreakpointObserver,
    public pageState: PageStateService,
    private sidenavService: SidenavService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private authenticatedUserService: AuthenticatedUserService,
    private userService: UserService,
    private bankingDetailsService: BankingDetailsService,
    private authenticationService: AuthenticationService,
    private title: Title,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.watchBreakpointMobileObservable();
    this.setupAnalytics();
    this.watchAuthenticationLoadingObservable();
    this.watchApplicationStateObservable();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.navigationSubscription.unsubscribe();
    this.analyticsService.unregisterAllStrategies();
  }

  private watchNavigationObservable() {
    return this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.router.routerState.snapshot.url !== this.currentRoute) {
        this.currentRoute = this.router.routerState.snapshot.url;
        this.rightSidePanel?.close();
        if (event.urlAfterRedirects === '/embedded/embedlogin') {
          return;
        }
        this.trackPage();
      }
    });
  }

  private watchApplicationStateObservable() {
    const sub = this.appStateService.applicationState$.subscribe((appState) => {
      this.isAuthenticated = appState.isAuthenticated();
      if (!this.isAuthenticated) {
        this.startedLoading = false;
      }
      this.isLoadingAuthenticatedUser = this.isAuthenticated && (!appState.isUserLoaded() || !appState.isReady());
      if (this.isAuthenticated && this.isLoadingAuthenticatedUser && !this.startedLoading) {
        this.startedLoading = true;
        this.authenticatedUserService.loadAuthenticatedUser().pipe(first()).subscribe();
      }
      this.hasAcceptedTerms = appState.isReady();
      if (this.hasAcceptedTerms) {
        const subscription = this.bankingDetailsService
          .retrieveWorkerBankingDetails(this.authenticatedUserService.authenticatedUser.id)
          .subscribe((workerBankingDetails) => {
            this.hasBankingDetails =
              workerBankingDetails.filter((workerBankingDetail) => workerBankingDetail.externalAccountId).length > 0;

            if (appState.isReady() && this.hasBankingDetails) {
              this.setDynamicSidenavReference();
              const user = this.authenticatedUserService.authenticatedUser;
              this.setSentryContext(user);
              this.analyticsService.identify(user);
            }
          });
        this.subscriptions.add(subscription);
      }
    });
    this.subscriptions.add(sub);
  }

  private watchAuthenticationLoadingObservable() {
    const sub = this.authenticatedUserService.authenticatedUser$.subscribe((authenticatedUser) => {
      if (authenticatedUser) {
        this.isLoadingAuthenticatedUser = false;
        if (this.navigationSubscription) {
          this.navigationSubscription.unsubscribe();
        }
        this.trackPage();
        this.navigationSubscription = this.watchNavigationObservable();
      }
      this.user = authenticatedUser;
    });
    this.subscriptions.add(sub);
  }

  public toggleHasBankingDetails() {
    this.hasBankingDetails = true;
    window.location.reload();
  }

  private watchBreakpointMobileObservable() {
    const sub = this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
        this.isExpanded = false;
      } else {
        this.isMobile = false;
        this.isExpanded = true;
      }
    });
    this.subscriptions.add(sub);
  }

  private setupAnalytics(): void {
    if (environment.analytics.enabled) {
      this.analyticsService.registerStrategy(this.segmentStrategy);
    }
  }

  public toggleTermsAndConditions(): void {
    this.showTermsAndConditions = true;
    this.showPrivacyPolicy = false;
  }
  public togglePrivacyPolicy(): void {
    this.showPrivacyPolicy = true;
    this.showTermsAndConditions = false;
  }

  public updateTermsAgreed(): void {
    const updatedUser = cloneDeep(this.user);
    let latestTermsVersion = AppComponent.latestTerms;
    if (updatedUser.countryCode === CountryCode.UnitedStatesOfAmerica) {
      latestTermsVersion = AppComponent.latestTermsUsa;
    }
    updatedUser.termsAgreed = latestTermsVersion;
    const updateRequest = this.userService.updateTermsAndConditions(updatedUser);
    this.pageState.setSavingObservable(updateRequest);
    updateRequest.subscribe(() => {
      this.hasAcceptedTerms = true;
    });
  }

  private setDynamicSidenavReference(): void {
    setTimeout(() => {
      this.sidenavService.setPanel(this.rightSidePanel);
      this.sidenavService.setContentVcf(this.viewContainerRef);
    });
  }

  public logout() {
    this.authenticationService.logout();
  }

  private setSentryContext(user: AuthenticatedUser): void {
    Sentry.setContext('user', {
      id: user.id,
      role: UserRole[user.roleId],
      roleId: user.roleId,
      companyId: user.companyId,
    });
  }

  private trackPage(): void {
    setTimeout(() => {
      let title = this.title.getTitle()?.replace('Paidiem - ', '') ?? this.router.url;
      const routeAnalyticsData = this.findAnalyticsRouteData(this.activatedRoute.root);
      title = title === routeAnalyticsData?.category ? 'Listing' : title;
      this.analyticsService.page(routeAnalyticsData?.category || 'Uncategorized', title);
    });
  }

  private findAnalyticsRouteData(route: ActivatedRoute): { category: string } {
    const routeChild = route.children[0];
    if (routeChild?.snapshot?.data?.['analytics']) {
      return routeChild.snapshot.data['analytics'];
    }
    return this.findAnalyticsRouteData(routeChild);
  }
}
