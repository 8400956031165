import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DailyPayApproverRedirectGuard {
  public constructor(private router: Router) {}

  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // removes the quotes around: '"token"'
    // const cleanedToken = activatedRouteSnapshot.queryParams['token']?.replace(/^'?"(.*)"'?$/, '$1');
    this.router.navigate(['daily-pay-approver', 'payment-request'], {
      queryParams: {
        token: activatedRouteSnapshot.queryParams['token'],
        _tsid: activatedRouteSnapshot.queryParams['_tsid'],
        _uin: activatedRouteSnapshot.queryParams['_uin'],
      },
      replaceUrl: true,
    });
    return true;
  }
}
