import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing, init, instrumentAngularRouting } from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from '@environment';

init({
  dsn: environment.sentry.dsn,
  environment: environment.environment,
  enabled: environment.sentry.enabled,
  tracePropagationTargets: environment.sentry.tracePropagationTargets,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: instrumentAngularRouting,
    }),
  ],
  tracesSampleRate: environment.sentry.tracesSampleRate,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
