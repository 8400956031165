import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { PushNotificationService } from '@services/push-notification/push-notification.service';
import { AuthenticatedUserService } from '@services/authenticated-user/authenticated-user.service';
import { PushNotificationCollection } from '@entities/push-notification/push-notification-collection.entity';
import { Pager } from '@entities/pagers/pager.class';
import { QueryFilterOperator, QueryFilters } from '@entities/shared/query-filters.class';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateUtil } from '@utils/date.util';
import { LoaderComponent } from '@core/components/states/loader/loader.component';
import { ButtonComponent } from '@core/components/buttons/button.component';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';
import { finalize } from 'rxjs';

@Component({
  selector: 'pd-push-notification-button',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatMenuModule, LoaderComponent],
  templateUrl: './push-notification-button.component.html',
  styles: [],
})
export class PushNotificationButtonComponent implements OnInit {
  public notificationsPager: Pager<PushNotificationCollection> = new Pager<PushNotificationCollection>();
  public queryFilters = new QueryFilters();
  public isLoading = false;
  private user = new AuthenticatedUser();
  public constructor(
    private pushNotificationService: PushNotificationService,
    private authenticatedUserService: AuthenticatedUserService
  ) {}
  public ngOnInit(): void {
    this.user = this.authenticatedUserService.authenticatedUser;
    if (this.user.lastNotificationCheckedAt) {
      this.queryFilters.addDateFilter(
        'createdAt',
        format(parseISO(this.user.lastNotificationCheckedAt), DateUtil.defaultDateFnsFormat),
        QueryFilterOperator.GreaterThanEqual
      );
    }
  }

  public onNotificationsOpened(): void {
    this.isLoading = true;
    this.pushNotificationService
      .retrieveNotificationsByUserId(this.notificationsPager, this.queryFilters)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (notifications) => {
          this.notificationsPager = notifications;
        },
      });
    this.pushNotificationService.updateLastCheckedPushNotificationForUser(this.user.id).subscribe((updatedUser) => {
      this.authenticatedUserService.authenticatedUser.lastNotificationCheckedAt = updatedUser.lastNotificationCheckedAt;
      this.user = this.authenticatedUserService.authenticatedUser;
    });
  }
}
