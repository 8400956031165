import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ButtonComponent } from '@core/components/buttons/button.component';
import { PageStateService } from '@services/page-state/page-state.service';
import { IconComponent } from '@core/components/icon/icon.component';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Router } from '@angular/router';
import { ThemeToggleComponent } from '@core/components/theme-toggle/theme-toggle.component';

// POC //

@Component({
  selector: 'pd-embedded-auth-otp',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    IconComponent,
    ThemeToggleComponent,
  ],
  providers: [PageStateService],
  templateUrl: './embedded-auth-otp.component.html',
})
export class EmbeddedAuthOtpComponent implements OnInit {
  public isLoading: boolean = false;
  public errorMessage: string;
  public userId: number = null;
  public codeSent: boolean = false;
  public codeSentAt: Date = null;
  public verifyMfaForm = new FormGroup({
    code: new FormControl('', Validators.required),
  });

  public constructor(
    public pageState: PageStateService,
    private authenticationService: AuthenticationService,
    public router: Router
  ) {}

  public ngOnInit(): void {
    const url = this.router.url.split('/');
    if (Number(url[url.length - 1])) {
      console.log('ID', Number(url[url.length - 1]));
      this.userId = Number(url[url.length - 1]);
    } else {
      console.log('NO ID');
    }

    if (this.authenticationService.isLoggedIn()) {
      this.authenticationService.navigateToEmbeddedDash(this.userId);
    }
  }

  public requestCode(): void {
    this.errorMessage = null;
    const codeRequest = this.authenticationService.requestOtpCode(this.userId);
    this.pageState.setSavingObservable(codeRequest);
    codeRequest.subscribe({
      next: () => {
        this.codeSentAt = new Date();
      },
      error: (err) => {
        if (err.error.code == 'InvalidCredentials') {
          this.errorMessage = 'Unable to utilize phone number on record.';
        }
        throw err;
      },
    });
  }

  public verifyCode(): void {
    const request = this.authenticationService.verifyEmbeddedOtpLogin(
      this.userId,
      this.verifyMfaForm.controls.code.value.toString()
    );
    this.pageState.setSavingObservable(request);
    request.subscribe({
      next: () => {
        this.authenticationService.navigateToEmbeddedDash(this.userId);
      },
      error: (err) => {
        throw err;
      },
    });
  }
}
