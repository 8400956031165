import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Breadcrumb, BreadcrumbService } from '@services/breadcrumbs/breadcrumb.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { IconComponent } from '@core/components/icon/icon.component';

@Component({
  selector: 'pd-breadcrumbs',
  standalone: true,
  imports: [CommonModule, RouterModule, TruncatePipe, IconComponent],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  public breadcrumbTrail: Breadcrumb[] = [];
  public isLoading = false;
  public constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.breadcrumbs$.pipe(takeUntilDestroyed()).subscribe((trail) => {
      this.breadcrumbTrail = trail;
      this.isLoading =
        this.breadcrumbTrail.filter((breadcrumb) => {
          return breadcrumb.isLoading === true;
        }).length > 0;
    });
  }
}
