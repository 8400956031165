<div class="flex h-full flex-col items-center justify-center antialiased" *ngIf="isLoadingAuthenticatedUser">
  <img src="assets/paidiem_logo.svg" width="100" height="100" class="anim-breathing" />
  Loading your information...
</div>
<div class="flex h-full flex-col items-center justify-center px-2 antialiased" *ngIf="isAuthenticated && !hasAcceptedTerms && user">
  <div *ngIf="!showPrivacyPolicy && !showTermsAndConditions">
    <div class="mb-4 text-xl font-bold" data-cy="textWelcomePaidiem">Welcome To Paidiem!</div>
    <div class="font-bold">
      Please review our
      <a class="underline" data-cy="linkTermsConditions" href="javascript:void(0)" (click)="toggleTermsAndConditions()"
        >Terms & Conditions</a
      >
      and
      <a class="underline" href="javascript:void(0)" data-cy="linkPrivacyPolicy" (click)="togglePrivacyPolicy()">Privacy Policy</a> before
      proceeding
    </div>
    <mat-checkbox color="primary" class="my-4" data-cy="checkboxAgreeTerms" [(ngModel)]="hasAgreed">
      <div class="text-base">I {{ user?.fullName }} have reviewed, understand and accept these Terms & Conditions and Privacy Policy.</div>
    </mat-checkbox>
    <div>By clicking "Submit", you understand and agree to Paidiem's Terms & Conditions and Privacy Policy</div>
    <button
      pd-button
      class="mt-4"
      data-cy="buttonSubmitTerms"
      (click)="updateTermsAgreed()"
      [loading]="pageState.isSaving"
      [disabled]="!hasAgreed || pageState.isSaving">
      Submit
    </button>
  </div>

  <div class="m-4 flex flex-col overflow-auto" *ngIf="showTermsAndConditions">
    <div class="mb-2 flex flex-1 flex-row">
      <div class="flex-1 self-center text-center text-lg font-bold">Terms & Conditions</div>
      <button
        pd-button
        icon="close"
        data-cy="buttonCloseTerms"
        color="secondary"
        class="self-end"
        (click)="showTermsAndConditions = false"></button>
    </div>
    <pd-terms-and-conditions *ngIf="user.countryCode !== 'USA'"></pd-terms-and-conditions>
    <pd-terms-and-conditions-usa *ngIf="user.countryCode === 'USA'"></pd-terms-and-conditions-usa>
    <button pd-button class="mt-4 self-center" (click)="showTermsAndConditions = false">Close</button>
  </div>
  <div class="m-4 flex flex-col overflow-auto" *ngIf="showPrivacyPolicy">
    <div class="mb-2 flex flex-1 flex-row">
      <div class="flex-1 self-center text-center text-lg font-bold">Privacy Policy</div>
      <button
        pd-button
        icon="close"
        data-cy="buttonClosePrivacy"
        color="secondary"
        class="self-end"
        (click)="showPrivacyPolicy = false"></button>
    </div>
    <pd-privacy-policy *ngIf="user.countryCode !== 'USA'"></pd-privacy-policy>
    <pd-privacy-policy-usa *ngIf="user.countryCode === 'USA'"></pd-privacy-policy-usa>
    <button pd-button class="mt-4 self-center" (click)="showPrivacyPolicy = false">Close</button>
  </div>
</div>
<div
  class="flex h-full flex-col items-center justify-center px-2 antialiased"
  *ngIf="isAuthenticated && user && user.isWorker && !hasBankingDetails">
  <pd-tabapay-embedded-iframe [onDebitCardDetailsCreation]="toggleHasBankingDetails"></pd-tabapay-embedded-iframe>
</div>

<div
  class="flex h-full flex-col items-center antialiased"
  *ngIf="(!isLoadingAuthenticatedUser && !user) || (isAuthenticated && user && (!user.isWorker || hasBankingDetails) && hasAcceptedTerms)">
  <mat-drawer-container class="flex flex-auto py-2" [hasBackdrop]="false" autosize>
    <router-outlet></router-outlet>

    <!-- <mat-drawer #rightPanel mode="over" class="flex min-w-[325px] max-w-[575px] border-2 border-yellow-500" > -->
    <ng-container #rightPanelContent></ng-container>
    <!-- </mat-drawer> -->
  </mat-drawer-container>
</div>
