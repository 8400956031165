import { Component } from '@angular/core';
import { PageStateService } from '@services/page-state/page-state.service';
import { PageTitleStrategy } from '@core/strategies/page-title.strategy';

@Component({
  selector: 'pd-app',
  providers: [PageStateService, PageTitleStrategy],
  templateUrl: './app.component.html',
  styles: [':host { height: 100%;}'],
})
export class AppComponent {
  public static latestTerms = '1.1';
  public static latestTermsUsa = '1.0';
  public static latestDisclosure = '1.0';
}
