<div class="flex flex-row flex-wrap">
  <ng-container *ngFor="let crumb of breadcrumbTrail; let index = index; let last = last">
    <pd-icon class="self-center px-2" icon="arrow-right" *ngIf="index !== 0">&gt;</pd-icon>
    <a *ngIf="!last" [routerLink]="crumb.url" class="flex flex-row" style="font-size: inherit; line-height: inherit">
      <div [ngClass]="crumb.isLoading ? 'anim-pulse h-[28px] w-32' : ''">
        {{ crumb.isLoading ? '' : (crumb.label | truncate : crumb.truncate ?? 20) }}
      </div>
    </a>
    <div *ngIf="last">
      <div [ngClass]="crumb.isLoading ? 'anim-pulse h-[28px] w-32' : ''">
        {{ crumb.isLoading ? '' : (crumb.label | truncate : crumb.truncate ?? 20) }}
      </div>
    </div>
  </ng-container>
</div>
