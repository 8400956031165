<form (ngSubmit)="verifyMfaForm.valid && verifyCode()" [formGroup]="verifyMfaForm" class="flex grow flex-col items-center p-2">
  <div *ngIf="codeSentAt" class="flex grow flex-col items-center">
    <div class="mb-6 text-center text-2xl font-bold">OTP Verification</div>
    <p class="mb-6 text-center">Please check your SMS for your code</p>
    <mat-form-field class="min-w-full">
      <mat-label class="text-center">Single Use Security Code</mat-label>
      <input
        matInput
        formControlName="code"
        data-cy="inputOTPCode"
        autofocus
        type="text"
        class="text-center"
        inputmode="numeric"
        [attr.autocomplete]="'one-time-code'" />
    </mat-form-field>
    <mat-error *ngIf="pageState.hasErrorSaving">{{ errorMessage || 'Something went wrong, please try again.' }}</mat-error>
    <button
      type="submit"
      class="min-w-full"
      pd-button
      data-cy="buttonVerifyCode"
      [loading]="pageState.isSaving"
      [disabled]="verifyMfaForm.invalid || pageState.isSaving">
      Verify
    </button>
    <!-- <p class="mt-8 text-sm italic">
      If you have not received an SMS with your Single Use Security Code, please contact
      <a href="mailto:support@paidiem.com" target="_blank">support&#64;paidiem.com</a> to help resolve the issue.
    </p> -->
    <button
      class="mt-4 max-w-3/4"
      color="secondary"
      pd-button
      data-cy="buttonResendCode"
      (click)="requestCode()"
      [loading]="pageState.isSaving"
      [disabled]="pageState.isSaving">
      Resend Code
    </button>
    <div *ngIf="codeSentAt" class="mt-2 text-xs italic">Code sent at {{ codeSentAt | date : 'hh:mm a' }}</div>
  </div>
  <div *ngIf="!codeSentAt" class="flex grow flex-col items-center">
    <div class="mb-6 text-center text-2xl font-bold">OTP Verification</div>
    <p class="mb-6 text-center">Please complete one time password verification to continue.</p>
    <button
      class="min-w-full"
      pd-button
      data-cy="buttonSendCode"
      (click)="requestCode()"
      [loading]="pageState.isSaving"
      [disabled]="pageState.isSaving">
      Send SMS Code
    </button>
  </div>
  <pd-theme-toggle class="mt-6"></pd-theme-toggle>
</form>
