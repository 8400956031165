<div class="flex flex-col justify-center">
  <div class="mb-4">To enable Instant Payments please enter your Debit Card.</div>

  <div #tabapayIframe></div>
  <div class="flex flex-col">
    <p class="mt-4 inline-block text-nowrap text-end text-2xs">
      POWERED BY<pd-paidiem-logo [showSlogan]="false" class="ml-2 inline-block max-w-24 align-middle"></pd-paidiem-logo>
    </p>
    <div class="flex flex-row justify-end">
      <img [width]="15" alt="United States" src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" />
    </div>
    <p class="mt-4 inline-block text-xs">
      By providing and selecting Use Card to submit debit-card details you agree to our
      <pd-policies-modal
        linkStyleClasses="text-xs text-primary dark:text-primary-light"
        [focusClass]="'.card_collection'"></pd-policies-modal>
    </p>
    <p class="mt-4 inline-block text-xs">
      For Customer Service contact <a class="text-xs underline" [href]="'mailto:support@paidiem.com'">support&#64;paidiem.com</a>
    </p>
  </div>
</div>
