<button
  data-cy="buttonNotifications"
  [matMenuTriggerFor]="notificationMenu"
  (menuOpened)="onNotificationsOpened()"
  pd-button
  color="none"
  icon="bell"></button>
<mat-menu #notificationMenu="matMenu">
  <button disabled mat-menu-item type="button" *ngIf="!isLoading && notificationsPager.data.length === 0">No new notifications</button>
  <button disabled mat-menu-item type="button" *ngIf="isLoading">
    <pd-loader size="xs"></pd-loader>
  </button>
  <button mat-menu-item type="button" class="my-1" *ngFor="let notification of notificationsPager.data">
    {{ notification.message }}
  </button>
</mat-menu>
