import { TrackingStrategy } from '../tracking.strategy';
import { AnalyticsBrowser } from '@segment/analytics-next';
import merge from 'lodash-es/merge';
import { UserRole } from '@entities/users/user-role.enum';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';

export class SegmentStrategy implements TrackingStrategy {
  public analytics: AnalyticsBrowser;
  public constructor(private writeKey: string) {}

  public identify(user: AuthenticatedUser, traits: object): void {
    const mergedTraits: any = merge(
      {
        user_role: UserRole[user.roleId],
        company: user.companyName,
        client: user.clientName,
      },
      traits
    );
    this.analytics.identify(user.id.toString(), mergedTraits);
  }

  public track(event: string, properties: object): void {
    this.analytics.track(event, properties);
  }

  public page(category: string, name: string, properties: object): void {
    this.analytics.page(category, name, properties);
  }

  public load(): void {
    this.analytics = AnalyticsBrowser.load({ writeKey: this.writeKey });
  }
}
