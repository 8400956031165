import { AppState } from './app-state.enum';

export class ApplicationState {
  public constructor(private state: AppState) {}

  public isAuthenticated(): boolean {
    return [AppState.Authenticated, AppState.Ready, AppState.UserLoaded].includes(this.state);
  }

  public isUserLoaded(): boolean {
    return [AppState.Ready, AppState.UserLoaded].includes(this.state);
  }

  public isUnauthenticated(): boolean {
    return this.state === AppState.Unauthenticated;
  }

  public is2FABlocked(): boolean {
    return this.state === AppState.Requires2FA;
  }

  public isReady(): boolean {
    return this.state === AppState.Ready;
  }
}
