import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  public constructor(private authService: AuthenticationService, private router: Router) {}
  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url === '/login' && this.authService.isLoggedIn()) {
      const defaultRoute = this.authService.getDefaultRoute();
      this.router.navigate(defaultRoute);
      return false;
    }
    return true;
  }
}
