import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handled: boolean = false;

    return next.handle(request).pipe(
      //taking out retry for now because it's even retrying on bad credentials
      //retry(1),
      catchError((returnedError) => {
        let errorMessage = null;
        console.log('Returned error', returnedError.error);

        if (returnedError.error instanceof ErrorEvent) {
          errorMessage = `Error: ${returnedError.error.message}`;
        } else if (returnedError instanceof HttpErrorResponse) {
          errorMessage = `Error Status ${returnedError.status}: ${returnedError.message}`;
          handled = this.handleServerSideError(returnedError);
        }

        console.error(errorMessage ? errorMessage : returnedError);

        if (!handled) {
          return throwError(() => returnedError);
        } else {
          return of(returnedError);
        }
      })
    );
  }

  private handleServerSideError(error: HttpErrorResponse): boolean {
    let handled: boolean = false;

    switch (error.status) {
      case 401:
        //we don't want to redirect people to the login page when they're already on
        //the login page
        if (this.router.url.includes('/embedded')) {
          this.authenticationService.embeddedLogout(this.router.url.split('/').pop());
          for (const dialog of this.dialog.openDialogs) {
            dialog.close();
          }
          handled = true;
        } else if (this.router.url !== '/login') {
          this.authenticationService.logout();
          for (const dialog of this.dialog.openDialogs) {
            dialog.close();
          }
          handled = true;
        }

        break;
    }

    return handled;
  }
}
